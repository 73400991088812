// Imports
import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash }
    if (savedPosition) return savedPosition

    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      component: () => import('@/layouts/home/Index.vue'),
      children: [
        {
          path: '',
          name: '',
          redirect: '/beranda',
          component: () => import('@/views/home/Index.vue'),
          meta: { menubar: true, tabs:0 },
        },
        {
          path: 'beranda',
          name: 'beranda',
          component: () => import('@/views/home/Index.vue'),
          meta: { menubar: true, tabs:0 },
        },
        {
          path: 'tentang-kami',
          name: 'tentang-kami',
          component: () => import('@/views/about/Index.vue'),
          meta: { menubar: true, tabs:2, src: require('@/assets/default-banner-home.jpg') },
        },
        {
          path: 'mekanisme-penjaminan',
          name: 'mekanisme-penjaminan',
          component: () => import('@/views/mechanism/Index.vue'),
          meta: { menubar: true, tabs:1, src: require('@/assets/default-banner-home.jpg') },
        },
        {
          path: 'manfaat-penjaminan',
          name: 'manfaat-penjaminan',
          component: () => import('@/views/benefit/Index.vue'),
          meta: { menubar: true, tabs:1, src: require('@/assets/default-banner-home.jpg') },
        },
        {
          path: 'proses-bisnis',
          name: 'proses-bisnis',
          component: () => import('@/views/process/Index.vue'),
          meta: { menubar: true, tabs:1, src: require('@/assets/default-banner-home.jpg') },
        },
        {
          path: 'tujuan-penjaminan',
          name: 'tujuan-penjaminan',
          component: () => import('@/views/purpose/Index.vue'),
          meta: { menubar: true, tabs:1, src: require('@/assets/default-banner-home.jpg') },
        },
        {
          path: 'sejarah-orionpenjaminan',
          name: 'sejarah-orionpenjaminan',
          component: () => import('@/views/aboutusdetail/Index.vue'),
          meta: { menubar: true, tabs:2, src: require('@/assets/banner-orion-temp.jpeg') },
        },
        {
          path: 'struktur-perusahaan',
          name: 'struktur-perusahaan',
          component: () => import('@/views/structure/Index.vue'),
          meta: { menubar: true, tabs:2, src: require('@/assets/banner-orion-temp.jpeg') },
        },
        // {
        //   path: 'penghargaan-perusahaan',
        //   name: 'penghargaan-perusahaan',
        //   component: () => import('@/views/awardsothers/Index.vue'),
        //   meta: { menubar: true, src: require('@/assets/banner-awards.jpg') },
        // },
        // {
        //   path: 'penghargaan-detail/:url',
        //   name: 'penghargaan-detail',
        //   component: () => import('@/views/awardsdetail/Index.vue'),
        //   meta: { menubar: false, src: require('@/assets/contact.jpg') },
        // },
        {
          path: 'laporan',
          name: 'laporan',
          component: () => import('@/views/financialreport/Index.vue'),
          meta: { menubar: true, tabs:2, src: require('@/assets/banner-orion-temp.jpeg') },
        },
        {
          path: 'produk',
          name: 'produk',
          component: () => import('@/views/products/Index.vue'),
          meta: { menubar: true, tabs:2, src: require('@/assets/banner-orion-temp.jpeg') },
        },
        {
          path: 'produk-detail/:url',
          name: 'produk-detail',
          component: () => import('@/views/productsdetail/Index.vue'),
          meta: { menubar: true, tabs:2, src: require('@/assets/banner-orion-temp.jpeg') },
        },
        {
          path: 'artikel-dan-informasi-terkini',
          name: 'artikel-dan-informasi-terkini',
          component: () => import('@/views/news/Index.vue'),
          meta: { menubar: true, tabs:2,src: require('@/assets/banner-orion-temp.jpeg') },
        },
        {
          path: 'artikel-detail/:url',
          name: 'artikel-detail',
          component: () => import('@/views/newsdetail/Index.vue'),
          meta: { menubar: true, tabs:2 },
        },
        {
          path: 'jaringan',
          name: 'jaringan',
          component: () => import('@/views/network/Index.vue'),
          meta: { menubar: true, tabs:4, src: require('@/assets/banner-orion-temp.jpeg') },
        },
        {
          path: 'core-values',
          name: 'core-values',
          component: () => import('@/views/corevalue/Index.vue'),
          meta: { menubar: true, tabs:3, src: require('@/assets/banner-orion-temp.jpeg') },
        },
        // {
        //   path: 'pengaduan',
        //   name: 'pengaduan',
        //   component: () => import('@/views/complain/Index.vue'),
        //   meta: { menubar: true, src: require('@/assets/banner-complain.jpg') },
        // },
        {
          path: 'karir',
          name: 'karir',
          component: () => import('@/views/career/Index.vue'),
          meta: { menubar: true, tabs:3, src: require('@/assets/pro.jpg') },
        },
        {
          path: 'client',
          name: 'client',
          component: () => import('@/views/client/Index.vue'),
          meta: { menubar: true, tabs:4, src: require('@/assets/pro.jpg') },
        },
        {
          path: 'agent',
          name: 'agent',
          component: () => import('@/views/agent/Index.vue'),
          meta: { menubar: true, tabs:4, src: require('@/assets/pro.jpg') },
        },
        {
          path: 'kontak-kami',
          name: 'kontak-kami',
          component: () => import('@/views/contact-us/Index.vue'),
          meta: { menubar: true },
        },
        {
          path: 'pengaduan-konsumen',
          name: 'pengaduan-konsumen',
          component: () => import('@/views/complain/Index.vue'),
          meta: { menubar: true },
        },
        // {
        //   path: 'karir-detail/:url',
        //   name: 'karir-detail',
        //   component: () => import('@/views/careerdetail/Index.vue'),
        //   meta: { menubar: false, src: require('@/assets/pro.jpg') },
        // },
        // {
        //   path: 'karir-insan',
        //   name: 'karir-insan',
        //   component: () => import('@/views/careergroup/Index.vue'),
        //   meta: { menubar: true, src: require('@/assets/banner-career.jpg'), subtitle: 'Takaful Karyawan' },
        // },
        // {
        //   path: 'karir-agen',
        //   name: 'karir-agen',
        //   component: () => import('@/views/careergroup/Index.vue'),
        //   meta: { menubar: true, src: require('@/assets/banner-agen.jpg'), subtitle: 'Takaful Marketing Executive'  },
        // },
        // {
        //   path: 'karir-magang',
        //   name: 'karir-magang',
        //   component: () => import('@/views/careergroup/Index.vue'),
        //   meta: { menubar: true, src: require('@/assets/banner-magang.jpg'), subtitle: 'Takaful Magang'  },
        // },
        // {
        //   path: 'polistakaful/:type/:id/:data',
        //   name: 'polistakaful',
        //   component: () => import('@/views/policy/Index.vue'),
        //   meta: { menubar: false, src: require('@/assets/pro.jpg') },
        // },
        // {
        //   path: 'klaim',
        //   name: 'klaim',
        //   component: () => import('@/views/claim/Index.vue'),
        //   meta: { menubar: true, src: require('@/assets/banner-claim.jpg') },
        // },
        // {
        //   path: 'faq',
        //   name: 'faq',
        //   component: () => import('@/views/faq/Index.vue'),
        //   meta: { menubar: true, src: require('@/assets/banner-faq.jpg') },
        // },
        // {
        //   path: 'perbedaan-takaful-dengan-konvensional',
        //   name: 'perbedaan-takaful-dengan-konvensional',
        //   component: () => import('@/views/different/Index.vue'),
        //   meta: { menubar: true, src: require('@/assets/banner-differents.jpg') },
        // },
        // {
        //   path: 'akun',
        //   name: 'akun',
        //   component: () => import('@/views/account/Index.vue'),
        //   meta: { menubar: false },
        // },
        // {
        //   path: 'login',
        //   name: 'login',
        //   component: () => import('@/views/login/Index.vue'),
        //   meta: { menubar: false},
        // },
        // {
        //   path: 'vcard/:id',
        //   name: 'vcard',
        //   component: () => import('@/views/vcard/Index.vue'),
        //   meta: { menubar: false },
        // },
        // {
        //   path: 'wbs',
        //   name: 'wbs',
        //   component: () => import('@/views/wbs/Index.vue'),
        //   meta: { menubar: true, src: require('@/assets/banner-wbs.jpg') },
        // },
        // {
        //   path: 'kalkulator-kontribusi',
        //   name: 'kalkulator-kontribusi',
        //   component: () => import('@/views/calculator/Index.vue'),
        //   meta: { menubar: false, src: require('@/assets/pro.jpg') },
        // },
        {
          path: 'scan',
          name: 'scan',
          component: () => import('@/views/scan/Index.vue'),
          meta: { menubar: false},
        },
        {
          path: '*',
          name: 'FourOhFour',
          component: () => import('@/views/404/Index.vue'),
          meta: { menubar: true },
        },
      ],
    },

  ],
})
export default router
