// Versioning
import * as packages from '../package.json'
// Connection JSON
import * as api from '../config.json'
export default {
    AppTitle () {
        const apptitle = api.app_title
        return apptitle
    },
    AppVersion () {
        const appversion = packages.version
        return appversion
    },
    AppURL () {
        const appurl = api.api_url
        return appurl
    },
    AppConnection () {
        const appconnection = api.api_connection
        return appconnection
    },
    //UsersID get local storage users id
    UsersID () {
        let usersid = 'website'
        return usersid
    },
    //FormatDateTime format date MM/DD/YYYY H:i:s
    FormatDateTimeSession () {
        let today = new Date()
        let dd = today.getDate()
        let mm = today.getMonth() + 1
        let yyyy = today.getFullYear()
        let hh = today.getHours()
        if (dd < 10) {
            dd = '0' + dd
        }
        if (mm < 10) {
            mm = '0' + mm
        }
        if (hh < 10) {
            hh = '0' + hh
        }
        today = yyyy + '-' + mm + '-' + dd + ' ' + hh
        return today
    },
    //SafeURL curl json api
    SafeURL (url, params) {
        const hosts = api.api_connection
        var keys = api.api_key
        var secret = api.api_secret
        const loginuser = this.UsersID()
        let newurl
        if (params !== '') {
            newurl = `${hosts}/${url}?key=${keys}&secret=${secret}&${params}&login_user=${loginuser}`
        } else {
            newurl = `${hosts}/${url}?key=${keys}&secret=${secret}&login_user=${loginuser}`
        }
        return newurl
    },
    //FormatDate format date MM/DD/YYYY
    FormatDate (date) {
        if (!date) return null
        const [year, month, day] = date.split('-')
        return `${month}/${day}/${year}`
    },
    //FormatDateTime format date MM/DD/YYYY H:i:s
    FormatDateTime (date) {
        if (!date) return null
        const [dates, times]        = date.split('T')
        const [year, month, day]    = dates.split('-')
        const newtime               = times.split('.')
        const splittime             = newtime[0].split(':')
        return `${month}/${day}/${year} ${splittime[0]}:${splittime[1]}`
    },
    //FormatDateTimeFull format date MM/DD/YYYY H:i:s
    FormatDateTimeFull (date) {
        if (!date) return null
        const [dates, times]     = date.split('T')
        const [year, month, day] = dates.split('-')
        const newtime            = times.split('.')
        const splittime          = newtime[0].split(':')
        const newmonth           = this.ShortMonthNameEng(month) 
        return `${newmonth} ${day} ${year} ${splittime[0]}:${splittime[1]}`
    },
    //FalseTrue true when value is Y
    FalseTrue (value) {
        let data = false
        if (value === 'Y') {
            data = true
        }
        return data
    },
    //FalseTrue true when value is 1
    FalseTrueBit (value) {
        let data = false
        if (value === 1) {
            data = true
        }
        return data
    },
    //Today get the day now
    Today () {
        let today = new Date()
        let dd = today.getDate()
        let mm = today.getMonth() + 1
        let yyyy = today.getFullYear()
        if (dd < 10) {
            dd = '0' + dd
        }
        if (mm < 10) {
            mm = '0' + mm
        }
        today = mm + '/' + dd + '/' + yyyy
        return today
    },
    //TodayTime get the day and time now
    TodayTime () {
        let today = new Date()
        let dd    = today.getDate()
        let mm    = today.getMonth() + 1
        let yyyy  = today.getFullYear()
        let time  = today.getTime() 
        if (dd < 10) {
            dd = '0' + dd
        }
        if (mm < 10) {
            mm = '0' + mm
        }
        today = mm + '/' + dd + '/' + yyyy + ' ' + time
        return today
    },
    //Tomorrow get the day is tomorrow
    Tomorrow () {
        let tomorrow = new Date()
        tomorrow.setDate(tomorrow.getDate() + 1)
        let newtomorrow = new Date(tomorrow)
        let dd = newtomorrow.getDate()
        let mm = newtomorrow.getMonth() + 1
        let yyyy = newtomorrow.getFullYear()
        if (dd < 10) {
            dd = '0' + dd
        }
        if (mm < 10) {
            mm = '0' + mm
        }
        newtomorrow = mm + '/' + dd + '/' + yyyy
        return newtomorrow
    },
    //NextWeek get the day is nextweek
    NextWeek () {
        let tomorrow = new Date()
        tomorrow.setDate(tomorrow.getDate() + 7)
        let newtomorrow = new Date(tomorrow)
        let dd = newtomorrow.getDate()
        let mm = newtomorrow.getMonth() + 1
        let yyyy = newtomorrow.getFullYear()
        if (dd < 10) {
            dd = '0' + dd
        }
        if (mm < 10) {
            mm = '0' + mm
        }
        newtomorrow = mm + '/' + dd + '/' + yyyy
        return newtomorrow
    },
    //ShortMonthNameEng MonthNameEnglish
    ShortMonthNameEng (months) {
        let monthsindo = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        let getmonth = monthsindo[parseInt(months) - 1]
        return getmonth
    },
    //MonthNameEng MonthNameEnglish
    MonthNameEng (months) {
        let monthsindo = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
        let getmonth = monthsindo[parseInt(months) - 1]
        return getmonth
    },
    //MonthNameEng MonthNameEnglish
    MonthNameIndo (months) {
        let monthsindo = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember']
        let getmonth = monthsindo[parseInt(months) - 1]
        return getmonth
    },
    //ShortMonthNameEng MonthNameEnglish
    ShortMonthNameIndo (months) {
        let monthsindo = ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Des']
        let getmonth = monthsindo[parseInt(months) - 1]
        return getmonth
    },
    //TodayTimeFull get the day and time now
    TodayTimeFull () {
        let today    = new Date()
        let dd       = today.getDate()
        let mm       = today.getMonth() + 1
        let yyyy     = today.getFullYear()
        let hours    = today.getHours() 
        let minute   = today.getMinutes() 
        let time     = hours +':'+minute
        if (dd < 10) {
            dd = '0' + dd
        }
        if (mm < 10) {
            mm = '0' + mm
        }
        today = this.ShortMonthNameEng(mm) + ' ' + dd + ' ' + yyyy + ' ' + time
        return today
    },
    //TodayFull get the day now
    TodayFull () {
        let today    = new Date()
        let dd       = today.getDate()
        let mm       = today.getMonth() + 1
        let yyyy     = today.getFullYear()
        if (dd < 10) {
            dd = '0' + dd
        }
        if (mm < 10) {
            mm = '0' + mm
        }
        today = this.ShortMonthNameEng(mm) + ' ' + dd + ' ' + yyyy
        return today
    },
    FormatNumber (value, fixed) {
        let val = (value / 1).toFixed(fixed).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    StringtoNumber (value) {
        let val = value.replace('.', ',')
        return val
    },
    //Format New Date
    FormatNewDate (dates) {
        let today = new Date(dates)
        let dd = today.getDate()
        let mm = today.getMonth() + 1
        let yyyy = today.getFullYear()
        if (dd < 10) {
            dd = '0' + dd
        }
        if (mm < 10) {
            mm = '0' + mm
        }
        today = mm + '/' + dd + '/' + yyyy
        return today
    },
    //FormatDateFull format date MM/DD/YYYY
    FormatDateFull (date) {
        if (!date) return null
        const dates              = date
        const [year, month, day] = dates.split('-')
        const newmonth           = this.ShortMonthNameEng(month) 
        return `${newmonth} ${day} ${year}`
    },
    //FormatDateTimeFull format date MM/DD/YYYY H:i:s
    FormatDateFullData (date) {
        if (!date) return null
        const [month, day, year] = date.split('/')
        const newmonth           = this.ShortMonthNameEng(month) 
        return `${newmonth} ${day} ${year}`
    },
    //Product
    Product (id, top) {
        let returns
        if (top === '') {
            switch (id) {
                case '0101':
                    returns = 'Properti'
                break
                case '0201':
                    returns = 'Mobil'
                break
                case '0205':
                    returns = 'Motor'
                break
                case '1003':
                    returns = 'PA Individu'
                break
                case '1004':
                    returns = 'PA Keluarga'
                break
            }
        } else {
            returns = top
        }
        return returns
    },
    //CurrentTime get time now
    CurrentTime () {
        let today = new Date()
        let hours    = today.getHours() 
        let minute   = today.getMinutes() 
        let time     = hours +':'+minute
        return time
    },
    //GetOnlyTimeFromDateTime format date H:i:s
    GetOnlyTimeFromDateTime (date) {
        if (!date) return null
        const times       = date.split('T')
        const newtime     = times[1].split('.')
        const splittime   = newtime[0].split(':')
        return `${splittime[0]}:${splittime[1]}`
    },
    //Feeduty
    Feeduty () {
        let feeduty = 40000
        return feeduty
    },
    GetIDSource () {
        let usersdetail = JSON.parse(localStorage.getItem('local_usersdetail'))
        let userstype   = usersdetail[0].users_type
        let careid      = ''
        if (userstype === 'AGEN') {
            careid = usersdetail[0].users_otherid
        }
        return careid
    },
    GetIDType () {
        let usersdetail = JSON.parse(localStorage.getItem('local_usersdetail'))
        let userstype   = usersdetail[0].users_type
        return userstype
    },
    DateDiff(a, b) {
        // Discard the time and time-zone information
        const _MS_PER_DAY = 1000 * 60 * 60 * 24
        let date1  = new Date(a)
        let date2  = new Date(b)
        const utc1 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate())
        const utc2 = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate())
        return Math.floor((utc2 - utc1) / _MS_PER_DAY);
    },
    MinuteDiff(time1, time2) {
        let diff     = new Date("01/01/2007 " + time2) - new Date("01/01/2007 " + time1)
        let diffHrs  = Math.floor((diff % 86400000) / 3600000); // hours
        let diffMins = Math.round(((diff % 86400000) % 3600000) / 60000); // minutes
        let returns  = diffHrs + ' Jam'
        if (diffMins > 0) {
            returns  += ' ' + diffMins + ' Menit'
        }
        return returns;
    },
    //Today get the day now
    TodayYYYYMMDD () {
        let today = new Date()
        let dd = today.getDate()
        let mm = today.getMonth() + 1
        let yyyy = today.getFullYear()
        if (dd < 10) {
            dd = '0' + dd
        }
        if (mm < 10) {
            mm = '0' + mm
        }
        today = yyyy + '-' + mm + '-' + dd
        return today
    },
    //TodayDateTime format date MM/DD/YYYY H:i:s
    TodayDateTime () {
        let today = new Date()
        let dd = today.getDate()
        let mm = today.getMonth() + 1
        let yyyy = today.getFullYear()
        let hh = today.getHours()
        let m = today.getMinutes()
        if (dd < 10) {
            dd = '0' + dd
        }
        if (mm < 10) {
            mm = '0' + mm
        }
        if (hh < 10) {
            hh = '0' + hh
        }
        today = yyyy + '-' + mm + '-' + dd + 'T' + hh +':'+m
        return today
    },
    //FormatDateFull format date MM/DD/YYYY
    FormatDateIndo (date) {
        if (!date) return null
        const dates              = date
        const [year, month, day] = dates.split('-')
        const newmonth           = this.MonthNameIndo(month) 
        return `${day} ${newmonth} ${year}`
    },
    DayNameIndo (date) {
        var days = ['Ahad', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu']
        var d = new Date(date)
        var dayname = days[d.getDay()]
        return dayname
    },
    //FormatDateFull format date MM/DD/YYYY
    FormatDateIndoWithDayName (date) {
        if (!date) return null
        const dates              = date
        const [year, month, day] = dates.split('-')
        const newmonth           = this.ShortMonthNameIndo(month)
        var dayname              = this.DayNameIndo(date)
        return `${dayname}, ${newmonth} ${day} ${year}`
    },
    CoalesceDate (date1, date2) {
        let returns = date1
        if (date1 === '1900-01-01') {
            returns = date2
        }
        return returns
    },
    TimeHour (time) {
        const [hours] = time.split(':')
        return `${hours}`
    },
    //LastMonth get the day last month
    LastMonth () {
        let today = new Date()
        today.setMonth(today.getMonth() - 1)
        let dd = today.getDate()
        let mm = today.getMonth() + 1
        let yyyy = today.getFullYear()
        if (dd < 10) {
            dd = '0' + dd
        }
        if (mm < 10) {
            mm = '0' + mm
        }
        today = yyyy + '-' + mm + '-' + dd
        return today
    },
    //YYYYMMDD format date YYYYMMDD
    YYYYMMDD (date) {
        if (!date) return null
        const [dates]            = date.split('T')
        const [year, month, day] = dates.split('-')
        const newmonth           = this.ShortMonthNameEng(month) 
        return `${newmonth} ${day} ${year}`
    },
    //FormatDateFull format date MM/DD/YYYY
    FormatDateFullSimple (date) {
        if (!date) return null
        const dates              = date
        const [year, month, day] = dates.split('-')
        const newmonth           = this.ShortMonthNameEng(month) 
        return `${day} ${newmonth} ${year.slice(year.length - 2)}`
    },
    //FormatDateTimeFullSimple format date MM/DD/YYYY H:i:s
    FormatDateTimeFullSimple (date) {
        if (!date) return null
        const [dates, times]     = date.split('T')
        const [year, month, day] = dates.split('-')
        const newtime            = times.split('.')
        const splittime          = newtime[0].split(':')
        const newmonth           = this.ShortMonthNameEng(month) 
        return `${day} ${newmonth}${year.slice(year.length - 0)}[${splittime[0]}:${splittime[1]}]`
    },
    FirstDateYear () {
        let today = new Date()
        let dd = '01'
        let mm = '01'
        let yyyy = today.getFullYear()
        today = yyyy + '-' + mm + '-' + dd
        return today
    },
    LastDateYear () {
        let today = new Date()
        let dd = '31'
        let mm = '12'
        let yyyy = today.getFullYear()
        today = yyyy + '-' + mm + '-' + dd
        return today
    },
    //TodayDateFullTime format date MM/DD/YYYY H:i:s
    TodayDateFullTime () {
        let today = new Date()
        let dd = today.getDate()
        let mm = today.getMonth() + 1
        let yyyy = today.getFullYear()
        let hh = today.getHours()
        let m = today.getMinutes()
        let s = today.getSeconds()
        if (dd < 10) {
            dd = '0' + dd
        }
        if (mm < 10) {
            mm = '0' + mm
        }
        if (hh < 10) {
            hh = '0' + hh
        }
        if (m < 10) {
            m = '0' + m
        }
        today = yyyy + '-' + mm + '-' + dd + ' ' + hh +':'+m+':'+s
        return today
    },
    //GetOnlyDateFromDateTime format data YYYY-MM-DD
    GetOnlyDateFromDateTime (date) {
        if (!date) return null
        let [dates]            = date.split('T')
        let [year, month, day] = dates.split('-')
        return `${year}-${month}-${day}`
    },
    Avatar () {
        let usersdetail = JSON.parse(localStorage.getItem('local_usersdetail'))
        let avatar   = usersdetail[0].users_avatar_url
        return avatar
    },
    Years () {
        let today    = new Date()
        let yyyy     = today.getFullYear()
        return yyyy
    },
    UrlPOST (url) {
        const hosts  = this.AppConnection()
        let newurl   = `${hosts}/${url}`
        return newurl
    },
    ParamPOST (param) {
        let key         = api.api_key
        let secret      = api.api_secret
        let login_user  = this.UsersID()
        var combine      = JSON.stringify({ key: key, secret: secret, login_user: login_user, "data": param })
        return combine
    },
    //FormatDateTimeFull format date MM/DD/YYYY H:i:s
    FormatDateFullOnlyDate (date) {
        if (!date) return null
        let split                = date.split('T')
        const dates              = split[0]
        const [year, month, day] = dates.split('-')
        const newmonth           = this.MonthNameIndo(month) 
        return ` ${day} ${newmonth} ${year}`
    },
    ReplaceAllString (str, find, replace) {
        return str.replace(new RegExp(find.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&'), 'g'), replace)
    },
    FixRounding(value, precision) {
        var power = Math.pow(10, precision || 0);
        return Math.round(value * power) / power;
    }
}